import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { BasicComponent } from './basic/basic.component';
import { AdvancedtableComponent } from './advancedtable/advancedtable.component';
import { TrxlogtableComponent } from './trxlogtable/trxlogtable.component';
import { CredittransferlogtableComponent } from './credittransferlogtable/credittransferlogtable.component';
import { ReversecredittransferlogtableComponent } from './reversecredittransferlogtable/reversecredittransferlogtable.component';
import { NotifsettlementlogtableComponent } from './notifsettlementlogtable/notifsettlementlogtable.component';
import { FailedmgmtlogtableComponent } from './failedmgmtlogtable/failedmgmtlogtable.component';
import { FailednotifsettlementlogtableComponent } from './failednotifsettlementlogtable/failednotifsettlementlogtable.component';
import { TrxsuspecttableComponent } from './trxsuspecttable/trxsuspecttable.component';

const routes: Routes = [
    {
        path: 'basic',
        component: BasicComponent
    },
    {
        path: 'advanced',
        component: AdvancedtableComponent
    },
    {
        path: 'trxlog',
        component: TrxlogtableComponent
    },
    {
        path: 'credittransferlog',
        component: CredittransferlogtableComponent
    },
    {
        path: 'reversecredittransferlog',
        component: ReversecredittransferlogtableComponent
    },
    {
        path: 'notifsettlementlog',
        component: NotifsettlementlogtableComponent
    },
    {
        path: 'failedmgmtlog',
        component: FailedmgmtlogtableComponent
    },
    {
        path: 'failednotifsettlementlog',
        component: FailednotifsettlementlogtableComponent
    },
    {
        path: 'trxsuspect',
        component: TrxsuspecttableComponent
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class TablesRoutingModule { }

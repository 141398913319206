const tableData = [
  {
    MESSAGEID: '20210301FASTIDJA710HRB12345671',
    LOCALDATETIME: '2021-03-01T19:00:00.000',
    TRACENUMBER: '345678',
    TRANSACTIONPROCESS: 'DEBET',
    TRANSFERTYPE: 'FITOFI',
    SOURCE: 'CHANNEL',
    DESTINATION: 'ESB',
    CHANNELID: '6011',
    ENDTOENDID: '20210301INDOIDJA110OR B12345678',
    AMOUNT: '11005.01',
    DEBTORNATIONALID: '102030405060708',
    DEBTORACCNUMBER: '123456789',
    DEBTORACCTYPE: 'SVGS',
    DEBITORNAME: 'JOHN SMITH',
    DEBITORTYPE: '01',
    DEBITORRESIDENT: '01',
    DEBITORTOWN: '0300',
    ORIPARTICIPANTBIC: 'INDOIDJA',
    PARTICIPANBIC: 'CENAIDJA',
    CREDITORNATIONALID: '102030405060708',
    CREDITORACCNUMBER: '987654321',
    CREDITORACCTYPE: 'SVGS',
    CREDITORNAME: 'GRACE',
    CREDITORTYPE: '01',
    CREDITORRESIDENT: '01',
    CREDITORTOWN: '0300',
    CREDITORTYPE2: '02',
    PURPOSEOFTRX: '11001',
    PAYMENTINFOTRX: 'Payment for housing',
    RCDETAIL: 'VLINK',
    REASONCODE: 'U000'
  },
  {
    MESSAGEID: '20210301FASTIDJA710HRB12345672',
    LOCALDATETIME: '2021-03-01T19:00:00.000',
    TRACENUMBER: '345678',
    TRANSACTIONPROCESS: 'DEBET',
    TRANSFERTYPE: 'FITOFI',
    SOURCE: 'CHANNEL',
    DESTINATION: 'ESB',
    CHANNELID: '6011',
    ENDTOENDID: '20210301INDOIDJA110OR B12345678',
    AMOUNT: '11005.01',
    DEBTORNATIONALID: '102030405060708',
    DEBTORACCNUMBER: '123456789',
    DEBTORACCTYPE: 'SVGS',
    DEBITORNAME: 'JOHN SMITH',
    DEBITORTYPE: '01',
    DEBITORRESIDENT: '01',
    DEBITORTOWN: '0300',
    ORIPARTICIPANTBIC: 'INDOIDJA',
    PARTICIPANBIC: 'CENAIDJA',
    CREDITORNATIONALID: '102030405060708',
    CREDITORACCNUMBER: '987654321',
    CREDITORACCTYPE: 'SVGS',
    CREDITORNAME: 'GRACE',
    CREDITORTYPE: '01',
    CREDITORRESIDENT: '01',
    CREDITORTOWN: '0300',
    CREDITORTYPE2: '02',
    PURPOSEOFTRX: '11001',
    PAYMENTINFOTRX: 'Payment for housing',
    RCDETAIL: 'VLINK',
    REASONCODE: 'U000'
  },
  {
    MESSAGEID: '20210301FASTIDJA710HRB12345673',
    LOCALDATETIME: '2021-03-01T19:00:00.000',
    TRACENUMBER: '345678',
    TRANSACTIONPROCESS: 'DEBET',
    TRANSFERTYPE: 'FITOFI',
    SOURCE: 'CHANNEL',
    DESTINATION: 'ESB',
    CHANNELID: '6011',
    ENDTOENDID: '20210301INDOIDJA110OR B12345678',
    AMOUNT: '11005.01',
    DEBTORNATIONALID: '102030405060708',
    DEBTORACCNUMBER: '123456789',
    DEBTORACCTYPE: 'SVGS',
    DEBITORNAME: 'JOHN SMITH',
    DEBITORTYPE: '01',
    DEBITORRESIDENT: '01',
    DEBITORTOWN: '0300',
    ORIPARTICIPANTBIC: 'INDOIDJA',
    PARTICIPANBIC: 'CENAIDJA',
    CREDITORNATIONALID: '102030405060708',
    CREDITORACCNUMBER: '987654321',
    CREDITORACCTYPE: 'SVGS',
    CREDITORNAME: 'GRACE',
    CREDITORTYPE: '01',
    CREDITORRESIDENT: '01',
    CREDITORTOWN: '0300',
    CREDITORTYPE2: '02',
    PURPOSEOFTRX: '11001',
    PAYMENTINFOTRX: 'Payment for housing',
    RCDETAIL: 'VLINK',
    REASONCODE: 'U000'
  },
  {
    MESSAGEID: '20210301FASTIDJA710HRB12345674',
    LOCALDATETIME: '2021-03-01T19:00:00.000',
    TRACENUMBER: '345678',
    TRANSACTIONPROCESS: 'DEBET',
    TRANSFERTYPE: 'FITOFI',
    SOURCE: 'CHANNEL',
    DESTINATION: 'ESB',
    CHANNELID: '6011',
    ENDTOENDID: '20210301INDOIDJA110OR B12345678',
    AMOUNT: '11005.01',
    DEBTORNATIONALID: '102030405060708',
    DEBTORACCNUMBER: '123456789',
    DEBTORACCTYPE: 'SVGS',
    DEBITORNAME: 'JOHN SMITH',
    DEBITORTYPE: '01',
    DEBITORRESIDENT: '01',
    DEBITORTOWN: '0300',
    ORIPARTICIPANTBIC: 'INDOIDJA',
    PARTICIPANBIC: 'CENAIDJA',
    CREDITORNATIONALID: '102030405060708',
    CREDITORACCNUMBER: '987654321',
    CREDITORACCTYPE: 'SVGS',
    CREDITORNAME: 'GRACE',
    CREDITORTYPE: '01',
    CREDITORRESIDENT: '01',
    CREDITORTOWN: '0300',
    CREDITORTYPE2: '02',
    PURPOSEOFTRX: '11001',
    PAYMENTINFOTRX: 'Payment for housing',
    RCDETAIL: 'VLINK',
    REASONCODE: 'U000'
  },
  {
    MESSAGEID: '20210301FASTIDJA710HRB12345675',
    LOCALDATETIME: '2021-03-01T19:00:00.000',
    TRACENUMBER: '345678',
    TRANSACTIONPROCESS: 'DEBET',
    TRANSFERTYPE: 'FITOFI',
    SOURCE: 'CHANNEL',
    DESTINATION: 'ESB',
    CHANNELID: '6011',
    ENDTOENDID: '20210301INDOIDJA110OR B12345678',
    AMOUNT: '11005.01',
    DEBTORNATIONALID: '102030405060708',
    DEBTORACCNUMBER: '123456789',
    DEBTORACCTYPE: 'SVGS',
    DEBITORNAME: 'JOHN SMITH',
    DEBITORTYPE: '01',
    DEBITORRESIDENT: '01',
    DEBITORTOWN: '0300',
    ORIPARTICIPANTBIC: 'INDOIDJA',
    PARTICIPANBIC: 'CENAIDJA',
    CREDITORNATIONALID: '102030405060708',
    CREDITORACCNUMBER: '987654321',
    CREDITORACCTYPE: 'SVGS',
    CREDITORNAME: 'GRACE',
    CREDITORTYPE: '01',
    CREDITORRESIDENT: '01',
    CREDITORTOWN: '0300',
    CREDITORTYPE2: '02',
    PURPOSEOFTRX: '11001',
    PAYMENTINFOTRX: 'Payment for housing',
    RCDETAIL: 'VLINK',
    REASONCODE: 'U000'
  },
  {
    MESSAGEID: '20210301FASTIDJA710HRB12345676',
    LOCALDATETIME: '2021-03-01T19:00:00.000',
    TRACENUMBER: '345678',
    TRANSACTIONPROCESS: 'DEBET',
    TRANSFERTYPE: 'FITOFI',
    SOURCE: 'CHANNEL',
    DESTINATION: 'ESB',
    CHANNELID: '6011',
    ENDTOENDID: '20210301INDOIDJA110OR B12345678',
    AMOUNT: '11005.01',
    DEBTORNATIONALID: '102030405060708',
    DEBTORACCNUMBER: '123456789',
    DEBTORACCTYPE: 'SVGS',
    DEBITORNAME: 'JOHN SMITH',
    DEBITORTYPE: '01',
    DEBITORRESIDENT: '01',
    DEBITORTOWN: '0300',
    ORIPARTICIPANTBIC: 'INDOIDJA',
    PARTICIPANBIC: 'CENAIDJA',
    CREDITORNATIONALID: '102030405060708',
    CREDITORACCNUMBER: '987654321',
    CREDITORACCTYPE: 'SVGS',
    CREDITORNAME: 'GRACE',
    CREDITORTYPE: '01',
    CREDITORRESIDENT: '01',
    CREDITORTOWN: '0300',
    CREDITORTYPE2: '02',
    PURPOSEOFTRX: '11001',
    PAYMENTINFOTRX: 'Payment for housing',
    RCDETAIL: 'VLINK',
    REASONCODE: 'U000'
  },
  {
    MESSAGEID: '20210301FASTIDJA710HRB12345677',
    LOCALDATETIME: '2021-03-01T19:00:00.000',
    TRACENUMBER: '345678',
    TRANSACTIONPROCESS: 'DEBET',
    TRANSFERTYPE: 'FITOFI',
    SOURCE: 'CHANNEL',
    DESTINATION: 'ESB',
    CHANNELID: '6011',
    ENDTOENDID: '20210301INDOIDJA110OR B12345678',
    AMOUNT: '11005.01',
    DEBTORNATIONALID: '102030405060708',
    DEBTORACCNUMBER: '123456789',
    DEBTORACCTYPE: 'SVGS',
    DEBITORNAME: 'JOHN SMITH',
    DEBITORTYPE: '01',
    DEBITORRESIDENT: '01',
    DEBITORTOWN: '0300',
    ORIPARTICIPANTBIC: 'INDOIDJA',
    PARTICIPANBIC: 'CENAIDJA',
    CREDITORNATIONALID: '102030405060708',
    CREDITORACCNUMBER: '987654321',
    CREDITORACCTYPE: 'SVGS',
    CREDITORNAME: 'GRACE',
    CREDITORTYPE: '01',
    CREDITORRESIDENT: '01',
    CREDITORTOWN: '0300',
    CREDITORTYPE2: '02',
    PURPOSEOFTRX: '11001',
    PAYMENTINFOTRX: 'Payment for housing',
    RCDETAIL: 'VLINK',
    REASONCODE: 'U000'
  },
  {
    MESSAGEID: '20210301FASTIDJA710HRB12345678',
    LOCALDATETIME: '2021-03-01T19:00:00.000',
    TRACENUMBER: '345678',
    TRANSACTIONPROCESS: 'DEBET',
    TRANSFERTYPE: 'FITOFI',
    SOURCE: 'CHANNEL',
    DESTINATION: 'ESB',
    CHANNELID: '6011',
    ENDTOENDID: '20210301INDOIDJA110OR B12345678',
    AMOUNT: '11005.01',
    DEBTORNATIONALID: '102030405060708',
    DEBTORACCNUMBER: '123456789',
    DEBTORACCTYPE: 'SVGS',
    DEBITORNAME: 'JOHN SMITH',
    DEBITORTYPE: '01',
    DEBITORRESIDENT: '01',
    DEBITORTOWN: '0300',
    ORIPARTICIPANTBIC: 'INDOIDJA',
    PARTICIPANBIC: 'CENAIDJA',
    CREDITORNATIONALID: '102030405060708',
    CREDITORACCNUMBER: '987654321',
    CREDITORACCTYPE: 'SVGS',
    CREDITORNAME: 'GRACE',
    CREDITORTYPE: '01',
    CREDITORRESIDENT: '01',
    CREDITORTOWN: '0300',
    CREDITORTYPE2: '02',
    PURPOSEOFTRX: '11001',
    PAYMENTINFOTRX: 'Payment for housing',
    RCDETAIL: 'VLINK',
    REASONCODE: 'U000'
  },
  {
    MESSAGEID: '20210301FASTIDJA710HRB12345679',
    LOCALDATETIME: '2021-03-01T19:00:00.000',
    TRACENUMBER: '345678',
    TRANSACTIONPROCESS: 'DEBET',
    TRANSFERTYPE: 'FITOFI',
    SOURCE: 'CHANNEL',
    DESTINATION: 'ESB',
    CHANNELID: '6011',
    ENDTOENDID: '20210301INDOIDJA110OR B12345678',
    AMOUNT: '11005.01',
    DEBTORNATIONALID: '102030405060708',
    DEBTORACCNUMBER: '123456789',
    DEBTORACCTYPE: 'SVGS',
    DEBITORNAME: 'JOHN SMITH',
    DEBITORTYPE: '01',
    DEBITORRESIDENT: '01',
    DEBITORTOWN: '0300',
    ORIPARTICIPANTBIC: 'INDOIDJA',
    PARTICIPANBIC: 'CENAIDJA',
    CREDITORNATIONALID: '102030405060708',
    CREDITORACCNUMBER: '987654321',
    CREDITORACCTYPE: 'SVGS',
    CREDITORNAME: 'GRACE',
    CREDITORTYPE: '01',
    CREDITORRESIDENT: '01',
    CREDITORTOWN: '0300',
    CREDITORTYPE2: '02',
    PURPOSEOFTRX: '11001',
    PAYMENTINFOTRX: 'Payment for housing',
    RCDETAIL: 'VLINK',
    REASONCODE: 'U000'
  }
];

export { tableData };

// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  defaultauth: 'fackbackend',
  firebaseConfig: {
    apiKey: 'AIzaSyBSZXYntc7T75qlnxZND84s_cAx5AuUtGo',
    authDomain: 'jweb-e628a.firebaseapp.com',
    databaseURL: 'https://jweb-e628a-default-rtdb.asia-southeast1.firebasedatabase.app',
    projectId: 'jweb-e628a',
    storageBucket: 'jweb-e628a.appspot.com',
    messagingSenderId: '126348628876',
    appId: '1:126348628876:web:df2e8fa1aaeb9cd442bca9',
    measurementId: 'G-CRQX3QM4H6'
  },
  apiUrl: 'http://147.139.130.112:8080/'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.

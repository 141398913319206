const tableData = [
  {
    username: 'Admin',
    activity: 'Update User',
    payload: '{"status": "Creator"}',
    date: '2021/10/30 19:00:00'
  },
  {
    username: 'Admin',
    activity: 'Update User',
    payload: '{"status": "Inactive"}',
    date: '2021/10/30 19:00:00'
  },
  {
    username: 'Admin',
    activity: 'Update User',
    payload: '{"role": "Viewer"}',
    date: '2021/10/30 19:00:00'
  }
];

export { tableData };

const tableData = [
  {
    MESSAGEID: '20210301FASTIDJA710HRB12345671',
    LOCALDATETIME: '2021-03-01T19:00:00.000',
    SOURCE: 'CHANNEL',
    DESTINATION: 'ESB',
    CHANNELID: '6011',
    ENDTOENDID: '20210301INDOIDJA110OR B12345678',
    AMOUNT: '11005.01',
    DEBTORNATIONALID: '102030405060708',
    DEBTORACCNUMBER: '123456789',
    DEBTORACCTYPE: 'SVGS',
    DEBTORAGENTID: 'INDOIDJA',
    CREDITORAGENTID: 'CENAIDJA',
    CREDITORACCNUMBER: '987654321',
    DEBTORSETTLEMENTACC: '654321098',
    SETTLEMENTACC: '890123456',
    REASONCODE: '68'
  },
  {
    MESSAGEID: '20210301FASTIDJA710HRB12345672',
    LOCALDATETIME: '2021-03-01T19:00:00.000',
    SOURCE: 'CHANNEL',
    DESTINATION: 'ESB',
    CHANNELID: '6011',
    ENDTOENDID: '20210301INDOIDJA110OR B12345678',
    AMOUNT: '11005.01',
    DEBTORNATIONALID: '102030405060708',
    DEBTORACCNUMBER: '123456789',
    DEBTORACCTYPE: 'SVGS',
    DEBTORAGENTID: 'INDOIDJA',
    CREDITORAGENTID: 'CENAIDJA',
    CREDITORACCNUMBER: '987654321',
    DEBTORSETTLEMENTACC: '654321098',
    SETTLEMENTACC: '890123456',
    REASONCODE: '68'
  },
  {
    MESSAGEID: '20210301FASTIDJA710HRB12345673',
    LOCALDATETIME: '2021-03-01T19:00:00.000',
    SOURCE: 'CHANNEL',
    DESTINATION: 'ESB',
    CHANNELID: '6011',
    ENDTOENDID: '20210301INDOIDJA110OR B12345678',
    AMOUNT: '11005.01',
    DEBTORNATIONALID: '102030405060708',
    DEBTORACCNUMBER: '123456789',
    DEBTORACCTYPE: 'SVGS',
    DEBTORAGENTID: 'INDOIDJA',
    CREDITORAGENTID: 'CENAIDJA',
    CREDITORACCNUMBER: '987654321',
    DEBTORSETTLEMENTACC: '654321098',
    SETTLEMENTACC: '890123456',
    REASONCODE: '68'
  },
  {
    MESSAGEID: '20210301FASTIDJA710HRB12345674',
    LOCALDATETIME: '2021-03-01T19:00:00.000',
    SOURCE: 'CHANNEL',
    DESTINATION: 'ESB',
    CHANNELID: '6011',
    ENDTOENDID: '20210301INDOIDJA110OR B12345678',
    AMOUNT: '11005.01',
    DEBTORNATIONALID: '102030405060708',
    DEBTORACCNUMBER: '123456789',
    DEBTORACCTYPE: 'SVGS',
    DEBTORAGENTID: 'INDOIDJA',
    CREDITORAGENTID: 'CENAIDJA',
    CREDITORACCNUMBER: '987654321',
    DEBTORSETTLEMENTACC: '654321098',
    SETTLEMENTACC: '890123456',
    REASONCODE: '68'
  },
  {
    MESSAGEID: '20210301FASTIDJA710HRB12345675',
    LOCALDATETIME: '2021-03-01T19:00:00.000',
    SOURCE: 'CHANNEL',
    DESTINATION: 'ESB',
    CHANNELID: '6011',
    ENDTOENDID: '20210301INDOIDJA110OR B12345678',
    AMOUNT: '11005.01',
    DEBTORNATIONALID: '102030405060708',
    DEBTORACCNUMBER: '123456789',
    DEBTORACCTYPE: 'SVGS',
    DEBTORAGENTID: 'INDOIDJA',
    CREDITORAGENTID: 'CENAIDJA',
    CREDITORACCNUMBER: '987654321',
    DEBTORSETTLEMENTACC: '654321098',
    SETTLEMENTACC: '890123456',
    REASONCODE: '68'
  },
  {
    MESSAGEID: '20210301FASTIDJA710HRB12345676',
    LOCALDATETIME: '2021-03-01T19:00:00.000',
    SOURCE: 'CHANNEL',
    DESTINATION: 'ESB',
    CHANNELID: '6011',
    ENDTOENDID: '20210301INDOIDJA110OR B12345678',
    AMOUNT: '11005.01',
    DEBTORNATIONALID: '102030405060708',
    DEBTORACCNUMBER: '123456789',
    DEBTORACCTYPE: 'SVGS',
    DEBTORAGENTID: 'INDOIDJA',
    CREDITORAGENTID: 'CENAIDJA',
    CREDITORACCNUMBER: '987654321',
    DEBTORSETTLEMENTACC: '654321098',
    SETTLEMENTACC: '890123456',
    REASONCODE: '68'
  }
];

export { tableData };

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { UsertableComponent } from './usertable/usertable.component';
import { RoletableComponent } from './roletable/roletable.component';
import { AudittrailtableComponent } from './audittrailtable/audittrailtable.component';

const routes: Routes = [
    {
        path: 'user',
        component: UsertableComponent
    },
    {
        path: 'role',
        component: RoletableComponent
    },
    {
        path: 'audittrail',
        component: AudittrailtableComponent
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class MasterRoutingModule { }

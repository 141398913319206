const tableData = [
  {
    MESSAGEID: '20210301FASTIDJA710HRB12345671',
    LOCALDATETIME: '2021-03-01T19:00:00.000',
    GMT: '2021-03-01T12:00:00.000',
    SOURCE: 'CHANNEL',
    DESTINATION: 'ESB',
    TERMINALID: 'IBR67187',
    ACQUIRERID: '151',
    OPERATIONTYPE: 'NEWR',
    MESSAGESENDERID: '123456789',
    PROXYTYPE: '01',
    PROXYVALUE: '62818888777666',
    REGISTRATIONID: '16271819',
    DISPLAYNAME: 'MR. JONATHAN',
    ACCOUNTNUMBER: '2040606090112',
    ACCOUNTNAME: 'JONATHAN',
    ACCOUNTTYPE: 'SVGS',
    SECONDIDTYPE: '01',
    SECONDIDVALUE: '5302022290990000',
    REGISTRATIONSTATUS: 'ACTV',
    CUSTOMERTYPE: '01',
    CUSTOMERID: '5302022290990000',
    CUSTOMERRESIDENT: '02',
    CUSTOMERTOWN: '0300',
    REASONCODE: 'U098',
    MESSAGE: ''
  },
  {
    MESSAGEID: '20210301FASTIDJA710HRB12345672',
    LOCALDATETIME: '2021-03-01T19:00:00.000',
    GMT: '2021-03-01T12:00:00.000',
    SOURCE: 'CHANNEL',
    DESTINATION: 'ESB',
    TERMINALID: 'IBR67187',
    ACQUIRERID: '151',
    OPERATIONTYPE: 'NEWR',
    MESSAGESENDERID: '123456789',
    PROXYTYPE: '01',
    PROXYVALUE: '62818888777666',
    REGISTRATIONID: '16271819',
    DISPLAYNAME: 'MR. JONATHAN',
    ACCOUNTNUMBER: '2040606090112',
    ACCOUNTNAME: 'JONATHAN',
    ACCOUNTTYPE: 'SVGS',
    SECONDIDTYPE: '01',
    SECONDIDVALUE: '5302022290990000',
    REGISTRATIONSTATUS: 'ACTV',
    CUSTOMERTYPE: '01',
    CUSTOMERID: '5302022290990000',
    CUSTOMERRESIDENT: '02',
    CUSTOMERTOWN: '0300',
    REASONCODE: 'U098',
    MESSAGE: ''
  },
  {
    MESSAGEID: '20210301FASTIDJA710HRB12345673',
    LOCALDATETIME: '2021-03-01T19:00:00.000',
    GMT: '2021-03-01T12:00:00.000',
    SOURCE: 'CHANNEL',
    DESTINATION: 'ESB',
    TERMINALID: 'IBR67187',
    ACQUIRERID: '151',
    OPERATIONTYPE: 'NEWR',
    MESSAGESENDERID: '123456789',
    PROXYTYPE: '01',
    PROXYVALUE: '62818888777666',
    REGISTRATIONID: '16271819',
    DISPLAYNAME: 'MR. JONATHAN',
    ACCOUNTNUMBER: '2040606090112',
    ACCOUNTNAME: 'JONATHAN',
    ACCOUNTTYPE: 'SVGS',
    SECONDIDTYPE: '01',
    SECONDIDVALUE: '5302022290990000',
    REGISTRATIONSTATUS: 'ACTV',
    CUSTOMERTYPE: '01',
    CUSTOMERID: '5302022290990000',
    CUSTOMERRESIDENT: '02',
    CUSTOMERTOWN: '0300',
    REASONCODE: 'U098',
    MESSAGE: ''
  },
  {
    MESSAGEID: '20210301FASTIDJA710HRB12345674',
    LOCALDATETIME: '2021-03-01T19:00:00.000',
    GMT: '2021-03-01T12:00:00.000',
    SOURCE: 'CHANNEL',
    DESTINATION: 'ESB',
    TERMINALID: 'IBR67187',
    ACQUIRERID: '151',
    OPERATIONTYPE: 'NEWR',
    MESSAGESENDERID: '123456789',
    PROXYTYPE: '01',
    PROXYVALUE: '62818888777666',
    REGISTRATIONID: '16271819',
    DISPLAYNAME: 'MR. JONATHAN',
    ACCOUNTNUMBER: '2040606090112',
    ACCOUNTNAME: 'JONATHAN',
    ACCOUNTTYPE: 'SVGS',
    SECONDIDTYPE: '01',
    SECONDIDVALUE: '5302022290990000',
    REGISTRATIONSTATUS: 'ACTV',
    CUSTOMERTYPE: '01',
    CUSTOMERID: '5302022290990000',
    CUSTOMERRESIDENT: '02',
    CUSTOMERTOWN: '0300',
    REASONCODE: 'U098',
    MESSAGE: ''
  },
  {
    MESSAGEID: '20210301FASTIDJA710HRB12345675',
    LOCALDATETIME: '2021-03-01T19:00:00.000',
    GMT: '2021-03-01T12:00:00.000',
    SOURCE: 'CHANNEL',
    DESTINATION: 'ESB',
    TERMINALID: 'IBR67187',
    ACQUIRERID: '151',
    OPERATIONTYPE: 'NEWR',
    MESSAGESENDERID: '123456789',
    PROXYTYPE: '01',
    PROXYVALUE: '62818888777666',
    REGISTRATIONID: '16271819',
    DISPLAYNAME: 'MR. JONATHAN',
    ACCOUNTNUMBER: '2040606090112',
    ACCOUNTNAME: 'JONATHAN',
    ACCOUNTTYPE: 'SVGS',
    SECONDIDTYPE: '01',
    SECONDIDVALUE: '5302022290990000',
    REGISTRATIONSTATUS: 'ACTV',
    CUSTOMERTYPE: '01',
    CUSTOMERID: '5302022290990000',
    CUSTOMERRESIDENT: '02',
    CUSTOMERTOWN: '0300',
    REASONCODE: 'U098',
    MESSAGE: ''
  },
  {
    MESSAGEID: '20210301FASTIDJA710HRB12345676',
    LOCALDATETIME: '2021-03-01T19:00:00.000',
    GMT: '2021-03-01T12:00:00.000',
    SOURCE: 'CHANNEL',
    DESTINATION: 'ESB',
    TERMINALID: 'IBR67187',
    ACQUIRERID: '151',
    OPERATIONTYPE: 'NEWR',
    MESSAGESENDERID: '123456789',
    PROXYTYPE: '01',
    PROXYVALUE: '62818888777666',
    REGISTRATIONID: '16271819',
    DISPLAYNAME: 'MR. JONATHAN',
    ACCOUNTNUMBER: '2040606090112',
    ACCOUNTNAME: 'JONATHAN',
    ACCOUNTTYPE: 'SVGS',
    SECONDIDTYPE: '01',
    SECONDIDVALUE: '5302022290990000',
    REGISTRATIONSTATUS: 'ACTV',
    CUSTOMERTYPE: '01',
    CUSTOMERID: '5302022290990000',
    CUSTOMERRESIDENT: '02',
    CUSTOMERTOWN: '0300',
    REASONCODE: 'U098',
    MESSAGE: ''
  }
];

export { tableData };

<div class="card">
  <div class="card-body">
    <div class="float-right">
      <select class="custom-select custom-select-sm">
        <option selected>Today</option>
        <option value="1">Nov</option>
        <option value="2">Oct</option>
        <option value="3">Sep</option>
        <option value="4">Aug</option>
      </select>
    </div>
    <p class="text-truncate font-size-14 mb-2">{{title}}</p>
    <div class="media">
      <div class="media-body overflow-hidden">
        <!-- <p class="text-truncate font-size-14 mb-2">{{title}}</p> -->
        <h5 class="mb-0">{{value}}</h5>
      </div>
      <div class="text-primary">
        <i class="{{icon}} font-size-24"></i>
      </div>
    </div>
  </div>

  <div class="card-body border-top py-3">
    <div class="text-truncate">
      <span class="badge badge-soft-success font-size-11"><i class="mdi mdi-menu-up"> </i> 2.4% </span>
      <span class="text-muted ml-2">From previous period</span>
    </div>
  </div>
</div>

<div class="container-fluid">
  <app-pagetitle title="Role" [breadcrumbItems]="breadCrumbItems"></app-pagetitle>

  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <!-- <h4 class="card-title">Role Data Table</h4> -->
          <!-- <p class="card-sub-title">
            Example of datatable.
          </p> -->
          <div class="text-right">
            <!-- Extra Large modal -->
            <button type="button" class="btn btn-primary mb-3" (click)="extraLarge(exlargeModal)">Add Role</button>
          </div>
          <div class="row mb-md-2">
            <div class="col-sm-12 col-md-6">
              <div class="dataTables_length" id="tickets-table_length"><label
                  class="d-inline-flex align-items-center">Show
                  <select name="tickets-table_length" aria-controls="tickets-table" name="pageSize"
                    [(ngModel)]="service.pageSize"
                    class="custom-select custom-select-sm form-control form-control-sm mx-2">
                    <option [ngValue]="10">10</option>
                    <option [ngValue]="25">25</option>
                    <option [ngValue]="50">50</option>
                    <option [ngValue]="100">100</option>
                  </select> entries</label></div>
            </div>
            <!-- Search -->
            <div class="col-sm-12 col-md-6">
              <div id="tickets-table_filter" class="dataTables_filter text-md-right"><label
                  class="d-inline-flex align-items-center">Search:
                  <input type="text" name="searchTerm" class="form-control form-control-sm ml-2"
                    aria-controls="tickets-table" [(ngModel)]="service.searchTerm"></label></div>
            </div>
            <!-- End search -->
          </div>
          <!-- Table -->
          <div class="table-responsive">
            <table id="basic-datatable"
              class="table table-bordered dt-responsive nowrap datatables no-footer dtr-inline">
              <thead>
                <tr>
                  <!-- <th></th> -->
                  <th sortable="name" (sort)="onSort($event)">Name</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody *ngFor="let table of tables$ | async;let i=index">
                <tr>
                  <!-- <td align="center">
                    <a class="expand-row" (click)="changeValue(i)">
                      +
                    </a>
                  </td> -->
                  <td>
                    <ngb-highlight [result]="table.name" [term]="service.searchTerm"></ngb-highlight>
                  </td>
                  <td>
                    <a class="dropdown-item" href="javascript: void(0);" (click)="extraLarge(exlargeModal,table.name)"><i
                      class="mdi mdi-account-edit"></i> Edit</a>
                    <!-- <div ngbDropdown placement="bottom-left">
                      <i class="mdi mdi-dots-vertical dropdown-toggle" ngbDropdownToggle data-toggle="dropdown"
                        aria-expanded="true"></i>
                      <div class="dropdown-menu dropdown-menu-right" ngbDropdownMenu>
                        <a class="dropdown-item" href="javascript: void(0);"><i class="mdi mdi-plus"></i>Add</a>
                        <a class="dropdown-item" href="javascript: void(0);" (click)="extraLarge(exlargeModal)"><i
                            class="mdi mdi-account-edit"></i>Edit</a>
                      </div>
                    </div> -->
                  </td>
                </tr>
                <!-- <tr *ngIf="!hideme[i]" class="sub-table no-bg">
                  <td align="center"> </td>
                  <td colspan="15" class="p-0">
                    <table class="table mb-0 table-striped">
                      <thead>
                        <tr>
                          <th>End date</th>
                          <th>Unit</th>
                          <th>Sales Account</th>
                          <th>Buy Account</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>{{table.enddate}}</td>
                          <td>{{table.unit}}</td>
                          <td>{{table.name}}</td>
                          <td>{{table.name}}</td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr> -->
              </tbody>
            </table>
          </div>
          <!-- End table -->
          <div class="row justify-content-md-between align-items-md-center mt-2">
            <div class="col-sm-12 col-md-5">
              <div class="dataTables_info mb-2" id="tickets-table_info" role="status" aria-live="polite">Showing
                {{service.startIndex}} to
                {{service.endIndex}} of {{service.totalRecords}}
                entries
              </div>
            </div>
            <!-- Pagination -->
            <div class="col-sm-12 col-md-5">
              <div class="text-md-right float-md-right pagination-rounded">
                <ngb-pagination [collectionSize]="total$ | async" [(page)]="service.page" [pageSize]="service.pageSize">
                </ngb-pagination>
              </div>
            </div>
            <!-- End Pagination -->
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Extra Large Modal -->
<ng-template #exlargeModal let-modal>
  <div class="modal-header">
    <h5 class="modal-title mt-0">Role Modal </h5>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Close click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="form-group row">
      <label for="example-text-input" class="col-md-2 col-form-label">Role Name</label>
      <div class="col-md-12">
        <input class="form-control" type="text" name="rolename" [(ngModel)]="rolename" id="example-text-input">
      </div>
      <!-- <ng-select class="form-control mb-4" [items]="selectValue"></ng-select> -->
    </div>
    <div class="table-responsive">
      <table class="table table-striped custom-table">
        <thead>
          <tr>
            <th>Permission</th>
            <th class="text-center">Read</th>
            <th class="text-center">Read Own</th>
            <th class="text-center">Update</th>
            <th class="text-center">Create</th>
            <th class="text-center">Delete</th>
            <th class="text-center">Import</th>
            <th class="text-center">Export</th>
          </tr>
        </thead>
        <tbody>
            <tr>
                <td>Dashboard</td>
                <td class="text-center">
                    <input name="permissions[dashboard][read]" checked="" type="checkbox">
                </td>
                <td class="text-center"></td>
                <td class="text-center"></td>
                <td class="text-center"></td>
                <td class="text-center"></td>
                <td class="text-center"></td>
                <td class="text-center"></td>
            </tr>
            <tr>
                <td>TRX Log</td>
                <td class="text-center">
                    <input name="permissions[logs][read]" checked="" type="checkbox">
                </td>
                <td class="text-center"></td>
                <td class="text-center"></td>
                <td class="text-center"></td>
                <td class="text-center"></td>
                <td class="text-center"></td>
                <td class="text-center">
                    <input name="permissions[logs][export]" type="checkbox">
                </td>
            </tr>
            <tr>
                <td>Credit Transfer Log</td>
                <td class="text-center">
                    <input name="permissions[logs][read]" checked="" type="checkbox">
                </td>
                <td class="text-center"></td>
                <td class="text-center"></td>
                <td class="text-center"></td>
                <td class="text-center"></td>
                <td class="text-center"></td>
                <td class="text-center">
                    <input name="permissions[logs][export]" type="checkbox">
                </td>
            </tr>
            <tr>
                <td>Reverse Credit Transfer Log</td>
                <td class="text-center">
                    <input name="permissions[logs][read]" checked="" type="checkbox">
                </td>
                <td class="text-center"></td>
                <td class="text-center"></td>
                <td class="text-center"></td>
                <td class="text-center"></td>
                <td class="text-center"></td>
                <td class="text-center">
                    <input name="permissions[logs][export]" type="checkbox">
                </td>
            </tr>
            <tr>
                <td>Notif Settlement Log</td>
                <td class="text-center">
                    <input name="permissions[logs][read]" checked="" type="checkbox">
                </td>
                <td class="text-center"></td>
                <td class="text-center"></td>
                <td class="text-center"></td>
                <td class="text-center"></td>
                <td class="text-center"></td>
                <td class="text-center">
                    <input name="permissions[logs][export]" type="checkbox">
                </td>
            </tr>
            <tr>
                <td>Failed MGMT Log</td>
                <td class="text-center">
                    <input name="permissions[logs][read]" checked="" type="checkbox">
                </td>
                <td class="text-center"></td>
                <td class="text-center"></td>
                <td class="text-center"></td>
                <td class="text-center"></td>
                <td class="text-center"></td>
                <td class="text-center">
                    <input name="permissions[logs][export]" type="checkbox">
                </td>
            </tr>
            <tr>
                <td>Failed Notif Settlement Log </td>
                <td class="text-center">
                    <input name="permissions[logs][read]" checked="" type="checkbox">
                </td>
                <td class="text-center"></td>
                <td class="text-center"></td>
                <td class="text-center"></td>
                <td class="text-center"></td>
                <td class="text-center"></td>
                <td class="text-center">
                    <input name="permissions[logs][export]" type="checkbox">
                </td>
            </tr>
            <tr>
                <td>TRX Suspect</td>
                <td class="text-center">
                    <input name="permissions[logs][read]" checked="" type="checkbox">
                </td>
                <td class="text-center"></td>
                <td class="text-center"></td>
                <td class="text-center"></td>
                <td class="text-center"></td>
                <td class="text-center"></td>
                <td class="text-center">
                    <input name="permissions[logs][export]" type="checkbox">
                </td>
            </tr>
            <tr>
                <td>User</td>
                <td class="text-center">
                    <input name="permissions[user][read]" checked="" type="checkbox">
                </td>
                <td class="text-center"></td>
                <td class="text-center">
                    <input name="permissions[user][update]" type="checkbox">
                </td>
                <td class="text-center">
                    <input name="permissions[user][create]" type="checkbox">
                </td>
                <td class="text-center">
                    <input name="permissions[user][delete]" type="checkbox">
                </td>
                <td class="text-center">
                    <input name="permissions[user][import]" type="checkbox">
                </td>
                <td class="text-center">
                    <input name="permissions[user][export]" type="checkbox">
                </td>
            </tr>
            <tr>
                <td>Role</td>
                <td class="text-center">
                    <input name="permissions[role][read]" checked="" type="checkbox">
                </td>
                <td class="text-center"></td>
                <td class="text-center">
                    <input name="permissions[role][update]" type="checkbox">
                </td>
                <td class="text-center">
                    <input name="permissions[role][create]" type="checkbox">
                </td>
                <td class="text-center">
                    <input name="permissions[role][delete]" type="checkbox">
                </td>
                <td class="text-center"></td>
                <td class="text-center">
                    <input name="permissions[role][export]" type="checkbox">
                </td>
            </tr>
            <tr>
                <td>Audit Trail</td>
                <td class="text-center">
                    <input name="permissions[audittrail][read]" checked="" type="checkbox">
                </td>
                <td class="text-center">
                    <input name="permissions[audittrail][read_own]" type="checkbox">
                </td>
                <td class="text-center"></td>
                <td class="text-center"></td>
                <td class="text-center"></td>
                <td class="text-center"></td>
                <td class="text-center">
                    <input name="permissions[attendance][export]" type="checkbox">
                </td>
            </tr>
        </tbody>
      </table>
    </div>
    <div class="submit-section">
      <button class="btn btn-primary submit-btn" (click)="clickme()">Save</button>
    </div>
  </div>
</ng-template>

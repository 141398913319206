import { Component, OnInit, ViewChildren, QueryList } from '@angular/core';
import { DecimalPipe } from '@angular/common';

import { Observable } from 'rxjs';

import { Table } from './user.model';

import { tableData } from './data';

import { UserService } from './user.service';
import { UserSortableDirective, SortEvent } from './user-sortable.directive';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-usertable',
  templateUrl: './usertable.component.html',
  styleUrls: ['./usertable.component.scss'],
  providers: [UserService, DecimalPipe]
})

/**
 * Advanced table component
 */
export class UsertableComponent implements OnInit {
  // bread crum data
  breadCrumbItems: Array<{}>;
  hideme: boolean[] = [];

  // Table data
  tableData: Table[];
  // Select2 Dropdown
  selectValue: string[];
  username: string = '';
  password: string = '';
  rolename: string = '';

  tables$: Observable<Table[]>;
  total$: Observable<number>;

  @ViewChildren(UserSortableDirective) headers: QueryList<UserSortableDirective>;

  constructor(public service: UserService, private modalService: NgbModal) {
    this.tables$ = service.tables$;
    this.total$ = service.total$;
  }
  ngOnInit() {

    this.breadCrumbItems = [{ label: 'Master' }, { label: 'User', active: true }];

    /**
     * fetch data
     */
    this._fetchData();

    // Select dropdown value
    // tslint:disable-next-line: max-line-length
    this.selectValue = ['Admin', 'Creator', 'Viewer'];
  }


  changeValue(i) {
    this.hideme[i] = !this.hideme[i];
  }

  /**
   * fetches the table value
   */
  _fetchData() {
    this.tableData = tableData;
    for (let i = 0; i <= this.tableData.length; i++) {
      this.hideme.push(true);
    }
  }

  /**
   * Sort table data
   * @param param0 sort the column
   *
   */
  onSort({ column, direction }: SortEvent) {
    // resetting other headers
    this.headers.forEach(header => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });
    this.service.sortColumn = column;
    this.service.sortDirection = direction;
  }

  /**
   * Open extra large modal
   * @param exlargeModal extra large modal data
   */
  extraLarge(exlargeModal: any, username: string = null, rolename: string = null) {
    this.username = username;
    this.rolename = rolename;
    this.modalService.open(exlargeModal, { size: 'xl' });
  }

  changeFn(rolename: string) {
    this.rolename = rolename;
  }

  clickme() {
    this.service.save(this.username, this.password, this.rolename);
    this.username, this.password, this.rolename = '';
    this.modalService.dismissAll('Close click');
  }
}

import { Component, OnInit, ViewChildren, QueryList } from '@angular/core';
import { DecimalPipe } from '@angular/common';

import { Observable } from 'rxjs';

import { Table } from './notifsettlementlog.model';

import { tableData } from './data';

import { NotifsettlementlogService } from './notifsettlementlog.service';
import { NotifsettlementlogSortableDirective, SortEvent } from './notifsettlementlog-sortable.directive';

import * as XLSX from 'xlsx';

declare var $:any;
declare var ExcelJS:any;
declare var DevExpress:any;
declare var saveAs:any;

@Component({
  selector: 'app-notifsettlementlogtable',
  templateUrl: './notifsettlementlogtable.component.html',
  styleUrls: ['./notifsettlementlogtable.component.scss'],
  providers: [NotifsettlementlogService, DecimalPipe]
})

/**
 * Advanced table component
 */
export class NotifsettlementlogtableComponent implements OnInit {
  // bread crum data
  breadCrumbItems: Array<{}>;
  hideme: boolean[] = [];

  // Table data
  tableData: Table[];

  tables$: Observable<Table[]>;
  total$: Observable<number>;

  @ViewChildren(NotifsettlementlogSortableDirective) headers: QueryList<NotifsettlementlogSortableDirective>;

  constructor(public service: NotifsettlementlogService) {
    this.tables$ = service.tables$;
    this.total$ = service.total$;
  }
  ngOnInit() {

    this.breadCrumbItems = [{ label: 'Monitoring' }, { label: 'Notif Settlement', active: true }];

    /**
     * fetch data
     */
    this._fetchData();

    $(() => {
      const dataGrid = $('#gridContainer').dxDataGrid({
        dataSource: tableData,
        keyExpr: 'MESSAGEID',
        columnsAutoWidth: true,
        showBorders: true,
        filterRow: {
          visible: true,
          applyFilter: 'auto',
        },
        searchPanel: {
          visible: true,
          width: 240,
          placeholder: 'Search...',
        },
        headerFilter: {
          visible: true,
        },
        paging: {
          pageSize: 10,
        },
        pager: {
          visible: true,
          allowedPageSizes: [10, 25, 50, 100, 'all'],
          showPageSizeSelector: true,
          showInfo: true,
          showNavigationButtons: true,
        },
        selection: {
          mode: 'multiple',
        },
        export: {
          enabled: true,
          allowExportSelectedData: true,
        },
        onExporting(e) {
          const workbook = new ExcelJS.Workbook();
          const worksheet = workbook.addWorksheet('Employees');

          DevExpress.excelExporter.exportDataGrid({
            component: e.component,
            worksheet,
            autoFilterEnabled: true,
          }).then(() => {
            workbook.xlsx.writeBuffer().then((buffer) => {
              saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'Employees.xlsx');
            });
          });
          e.cancel = true;
        },
        columns: [
          {
            dataField: 'MESSAGEID',
            width: 'auto',
          },
          {
            dataField: 'LOCALDATETIME',
            dataType: 'datetime',
            width: 'auto',
          },
          {
            dataField: 'SOURCE',
            width: 'auto',
          },
          {
            dataField: 'DESTINATION',
            width: 'auto',
          },
          {
            dataField: 'CHANNELID',
            width: 'auto',
          },
          {
            dataField: 'ENDTOENDID',
            width: 'auto',
          },
          {
            dataField: 'AMOUNT',
            width: 'auto',
          },
          {
            dataField: 'DEBTORNATIONALID',
            width: 'auto',
          },
          {
            dataField: 'DEBTORACCNUMBER',
            width: 'auto',
          },
          {
            dataField: 'DEBTORACCTYPE',
            width: 'auto',
          },
          {
            dataField: 'DEBITORNAME',
            width: 'auto',
          },
          {
            dataField: 'DEBTORTYPE',
            width: 'auto',
          },
          {
            dataField: 'DEBTORAGENTID',
            width: 'auto',
          },
          {
            dataField: 'DEBITORRESIDENT',
            width: 'auto',
          },
          {
            dataField: 'DEBITORTOWN',
            width: 'auto',
          },
          {
            dataField: 'CREDITORAGENTID',
            width: 'auto',
          },
          {
            dataField: 'CREDITORNATIONALID',
            width: 'auto',
          },
          {
            dataField: 'CREDITORACCNUMBER',
            width: 'auto',
          },
          {
            dataField: 'CREDITORACCTYPE',
            width: 'auto',
          },
          {
            dataField: 'CREDITORNAME',
            width: 'auto',
          },
          {
            dataField: 'CREDITORTYPE',
            width: 'auto',
          },
          {
            dataField: 'CREDITORRESIDENT',
            width: 'auto',
          },
          {
            dataField: 'CREDITORTOWN',
            width: 'auto',
          },
          // {
          //   dataField: 'OrderDate',
          //   alignment: 'right',
          //   dataType: 'date',
          //   width: 120,
          //   // calculateFilterExpression(value, selectedFilterOperations, target) {
          //   //   if (target === 'headerFilter' && value === 'weekends') {
          //   //     return [[getOrderDay, '=', 0], 'or', [getOrderDay, '=', 6]];
          //   //   }
          //   //   return this.defaultCalculateFilterExpression(value, selectedFilterOperations, target);
          //   // },
          //   // headerFilter: {
          //   //   dataSource(data) {
          //   //     data.dataSource.postProcess = function (results) {
          //   //       results.push({
          //   //         text: 'Weekends',
          //   //         value: 'weekends',
          //   //       });
          //   //       return results;
          //   //     };
          //   //   },
          //   // },
          // },
          // {
          //   dataField: 'DeliveryDate',
          //   alignment: 'right',
          //   dataType: 'datetime',
          //   width: 180,
          //   format: 'M/d/yyyy, HH:mm',
          // },
          // {
          //   dataField: 'SaleAmount',
          //   alignment: 'right',
          //   format: 'currency',
          //   editorOptions: {
          //     format: 'currency',
          //     showClearButton: true,
          //   },
          //   headerFilter: {
          //     dataSource: [{
          //       text: 'Less than $3000',
          //       value: ['SaleAmount', '<', 3000],
          //     }, {

          //       text: '$3000 - $5000',
          //       value: [['SaleAmount', '>=', 3000], ['SaleAmount', '<', 5000]],
          //     }, {

          //       text: '$5000 - $10000',
          //       value: [['SaleAmount', '>=', 5000], ['SaleAmount', '<', 10000]],
          //     }, {

          //       text: '$10000 - $20000',
          //       value: [['SaleAmount', '>=', 10000], ['SaleAmount', '<', 20000]],
          //     }, {
          //       text: 'Greater than $20000',
          //       value: ['SaleAmount', '>=', 20000],
          //     }],
          //   },
          // },
          // 'Employee',
          // {
          //   caption: 'City',
          //   dataField: 'CustomerStoreCity',
          //   headerFilter: {
          //     allowSearch: true,
          //   },
          // }
        ],
      }).dxDataGrid('instance');

      const applyFilterTypes = [{
        key: 'auto',
        name: 'Immediately',
      }, {
        key: 'onClick',
        name: 'On Button Click',
      }];

      const applyFilterModeEditor = $('#useFilterApplyButton').dxSelectBox({
        items: applyFilterTypes,
        value: applyFilterTypes[0].key,
        valueExpr: 'key',
        displayExpr: 'name',
        onValueChanged(data) {
          dataGrid.option('filterRow.applyFilter', data.value);
        },
      }).dxSelectBox('instance');

      $('#filterRow').dxCheckBox({
        text: 'Filter Row',
        value: true,
        onValueChanged(data) {
          dataGrid.clearFilter();
          dataGrid.option('filterRow.visible', data.value);
          applyFilterModeEditor.option('disabled', !data.value);
        },
      });

      $('#headerFilter').dxCheckBox({
        text: 'Header Filter',
        value: true,
        onValueChanged(data) {
          dataGrid.clearFilter();
          dataGrid.option('headerFilter.visible', data.value);
        },
      });

      function getOrderDay(rowData) {
        return (new Date(rowData.OrderDate)).getDay();
      }
    });
  }


  changeValue(i) {
    this.hideme[i] = !this.hideme[i];
  }

  /**
   * fetches the table value
   */
  _fetchData() {
    this.tableData = tableData;
    for (let i = 0; i <= this.tableData.length; i++) {
      this.hideme.push(true);
    }
  }

  /**
   * Sort table data
   * @param param0 sort the column
   *
   */
  onSort({ column, direction }: SortEvent) {
    // resetting other headers
    this.headers.forEach(header => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });
    this.service.sortColumn = column;
    this.service.sortDirection = direction;
  }

  exportExcel() {
    /* pass here the table id */
    let element = document.getElementById('basic-datatable');
    const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, 'Trxlog.xlsx');
  }
}

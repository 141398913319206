import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { UiModule } from '../../shared/ui/ui.module';
import { NgbPaginationModule, NgbTypeaheadModule, NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';

import { TablesRoutingModule } from './tables-routing.module';
import { AdvancedSortableDirective } from './advancedtable/advanced-sortable.directive';
import { TrxlogSortableDirective } from './trxlogtable/trxlog-sortable.directive';
import { CredittransferlogSortableDirective } from './credittransferlogtable/credittransferlog-sortable.directive';
import { ReversecredittransferlogSortableDirective } from './reversecredittransferlogtable/reversecredittransferlog-sortable.directive';
import { NotifsettlementlogSortableDirective } from './notifsettlementlogtable/notifsettlementlog-sortable.directive';
import { FailedmgmtlogSortableDirective } from './failedmgmtlogtable/failedmgmtlog-sortable.directive';
import { FailednotifsettlementlogSortableDirective } from './failednotifsettlementlogtable/failednotifsettlementlog-sortable.directive';
import { TrxsuspectSortableDirective } from './trxsuspecttable/trxsuspect-sortable.directive';
import { BasicComponent } from './basic/basic.component';
import { AdvancedtableComponent } from './advancedtable/advancedtable.component';
import { TrxlogtableComponent } from './trxlogtable/trxlogtable.component';
import { CredittransferlogtableComponent } from './credittransferlogtable/credittransferlogtable.component';
import { ReversecredittransferlogtableComponent } from './reversecredittransferlogtable/reversecredittransferlogtable.component';
import { NotifsettlementlogtableComponent } from './notifsettlementlogtable/notifsettlementlogtable.component';
import { FailedmgmtlogtableComponent } from './failedmgmtlogtable/failedmgmtlogtable.component';
import { FailednotifsettlementlogtableComponent } from './failednotifsettlementlogtable/failednotifsettlementlogtable.component';
import { TrxsuspecttableComponent } from './trxsuspecttable/trxsuspecttable.component';

@NgModule({
  declarations: [
    BasicComponent, 
    AdvancedtableComponent, 
    TrxlogtableComponent, 
    CredittransferlogtableComponent, 
    ReversecredittransferlogtableComponent, 
    NotifsettlementlogtableComponent, 
    FailedmgmtlogtableComponent, 
    FailednotifsettlementlogtableComponent, 
    TrxsuspecttableComponent, 
    AdvancedSortableDirective, 
    TrxlogSortableDirective,
    CredittransferlogSortableDirective,
    ReversecredittransferlogSortableDirective,
    NotifsettlementlogSortableDirective,
    FailedmgmtlogSortableDirective,
    FailednotifsettlementlogSortableDirective,
    TrxsuspectSortableDirective
  ],
  imports: [
    CommonModule,
    TablesRoutingModule,
    UiModule,
    NgbDropdownModule,
    NgbPaginationModule,
    NgbTypeaheadModule,
    FormsModule
  ]
})
export class TablesModule { }

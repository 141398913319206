<div class="container-fluid">
  <app-pagetitle title="Audittrail" [breadcrumbItems]="breadCrumbItems"></app-pagetitle>

  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <!-- <h4 class="card-title">Audittrail Data Table</h4> -->
          <!-- <p class="card-sub-title">
            Example of datatable.
          </p> -->

          <div class="dx-viewport">
            <div class="demo-container">
              <div id="gridContainer"></div>

              <!-- <div class="options">
                <div class="caption">Options</div>
                <div class="option">
                  <span>Apply Filter</span>
                  <div id="useFilterApplyButton"></div>
                </div>
                <div class="option">
                  <div id="filterRow"></div>
                </div>
                <div class="option">
                  <div id="headerFilter"></div>
                </div>
              </div> -->
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</div>

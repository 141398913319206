import { Component, OnInit, ViewChildren, QueryList } from '@angular/core';
import { DecimalPipe } from '@angular/common';

import { Observable } from 'rxjs';

import { Table } from './role.model';

import { tableData } from './data';

import { RoleService } from './role.service';
import { RoleSortableDirective, SortEvent } from './role-sortable.directive';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-roletable',
  templateUrl: './roletable.component.html',
  styleUrls: ['./roletable.component.scss'],
  providers: [RoleService, DecimalPipe]
})

/**
 * Advanced table component
 */
export class RoletableComponent implements OnInit {
  // bread crum data
  breadCrumbItems: Array<{}>;
  hideme: boolean[] = [];

  // Table data
  tableData: Table[];
  // Select2 Dropdown
  selectValue: string[];
  rolename: string = '';

  tables$: Observable<Table[]>;
  total$: Observable<number>;

  @ViewChildren(RoleSortableDirective) headers: QueryList<RoleSortableDirective>;

  constructor(public service: RoleService, private modalService: NgbModal) {
    this.tables$ = service.tables$;
    this.total$ = service.total$;
  }
  ngOnInit() {

    this.breadCrumbItems = [{ label: 'Master' }, { label: 'Role', active: true }];

    /**
     * fetch data
     */
    this._fetchData();

    // Select dropdown value
    // tslint:disable-next-line: max-line-length
    this.selectValue = ['Admin', 'Creator', 'Viewer'];
  }


  changeValue(i) {
    this.hideme[i] = !this.hideme[i];
  }

  /**
   * fetches the table value
   */
  _fetchData() {
    this.service.getAll.subscribe((data) => {
      this.tableData = data;
      // this.tableData = tableData;
      for (let i = 0; i <= this.tableData.length; i++) {
        this.hideme.push(true);
      }
    });
  }

  /**
   * Sort table data
   * @param param0 sort the column
   *
   */
  onSort({ column, direction }: SortEvent) {
    // resetting other headers
    this.headers.forEach(header => {
      if (header.sortable !== column) {
        header.direction = '';
      }
    });
    this.service.sortColumn = column;
    this.service.sortDirection = direction;
  }

  /**
   * Open extra large modal
   * @param exlargeModal extra large modal data
   */
  extraLarge(exlargeModal: any, rolename: string = null) {
    this.rolename = rolename;
    this.modalService.open(exlargeModal, { size: 'xl' });
  }

  clickme() {
    this.service.save(this.rolename);
    this.rolename = '';
    this.modalService.dismissAll('Close click');
  }
}

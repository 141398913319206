const tableData = [
  {
    name: 'Admin',
    role: 'Admin',
    status: 'Active'
  },
  {
    name: 'Creator',
    role: 'Creator',
    status: 'Active'
  },
  {
    name: 'Viewer',
    role: 'Viewer',
    status: 'Active'
  }
];

export { tableData };

<div class="container-fluid">

  <!-- start page title -->
  <app-pagetitle title="Dropdown" [breadcrumbItems]="breadCrumbItems"></app-pagetitle>
  <!-- end page title -->

  <div class="row">
    <div class="col-lg-6">
      <div class="card">
        <div class="card-body">

          <h4 class="card-title">Single button dropdowns</h4>
          <p class="card-title-desc">Any single <code class="highlighter-rouge">.btn</code> can be turned into a
            dropdown
            toggle with some markup changes. Here’s how you can put them to work
            with either <code class="highlighter-rouge">&lt;button&gt;</code>
            elements:</p>

          <div class="row">
            <div class="col-sm-6">
              <div class="dropdown" ngbDropdown>
                <button class="btn btn-primary dropdown-toggle" type="button" id="dropdownMenuButton"
                  ngbDropdownToggle>
                  Dropdown button <i class="mdi mdi-chevron-down"></i>
                </button>
                <div class="dropdown-menu" ngbDropdownMenu>
                  <a class="dropdown-item" href="javascript: void(0);">Action</a>
                  <a class="dropdown-item" href="javascript: void(0);">Another action</a>
                  <a class="dropdown-item" href="javascript: void(0);">Something else here</a>
                </div>
              </div>
            </div>

            <div class="col-sm-6">
              <div class="dropdown mt-4 mt-sm-0" ngbDropdown>
                <a class="btn btn-light dropdown-toggle" ngbDropdownToggle>
                  Dropdown link <i class="mdi mdi-chevron-down"></i>
                </a>
                <div class="dropdown-menu" ngbDropdownMenu>
                  <a class="dropdown-item" href="javascript: void(0);">Action</a>
                  <a class="dropdown-item" href="javascript: void(0);">Another action</a>
                  <a class="dropdown-item" href="javascript: void(0);">Something else here</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-lg-6">
      <div class="card">
        <div class="card-body">

          <h4 class="card-title">Variant</h4>
          <p class="card-title-desc mb-3">The best part is you can do this with any button variant, too:</p>

          <div class="">
            <div class="btn-group mr-1 mt-2" ngbDropdown>
              <button type="button" class="btn btn-primary dropdown-toggle" ngbDropdownToggle>Primary <i
                  class="mdi mdi-chevron-down"></i></button>
              <div class="dropdown-menu" ngbDropdownMenu>
                <a class="dropdown-item" href="javascript: void(0);">Action</a>
                <a class="dropdown-item" href="javascript: void(0);">Another action</a>
                <a class="dropdown-item" href="javascript: void(0);">Something else here</a>
                <div class="dropdown-divider"></div>
                <a class="dropdown-item" href="javascript: void(0);">Separated link</a>
              </div>
            </div><!-- /btn-group -->
            <div class="btn-group mr-1 mt-2" ngbDropdown>
              <button type="button" class="btn btn-light dropdown-toggle" ngbDropdownToggle>Light <i
                  class="mdi mdi-chevron-down"></i></button>
              <div class="dropdown-menu" ngbDropdownMenu>
                <a class="dropdown-item" href="javascript: void(0);">Action</a>
                <a class="dropdown-item" href="javascript: void(0);">Another action</a>
                <a class="dropdown-item" href="javascript: void(0);">Something else here</a>
                <div class="dropdown-divider"></div>
                <a class="dropdown-item" href="javascript: void(0);">Separated link</a>
              </div>
            </div><!-- /btn-group -->
            <div class="btn-group mr-1 mt-2" ngbDropdown>
              <button type="button" class="btn btn-success dropdown-toggle" ngbDropdownToggle>Success <i
                  class="mdi mdi-chevron-down"></i></button>
              <div class="dropdown-menu" ngbDropdownMenu>
                <a class="dropdown-item" href="javascript: void(0);">Action</a>
                <a class="dropdown-item" href="javascript: void(0);">Another action</a>
                <a class="dropdown-item" href="javascript: void(0);">Something else here</a>
                <div class="dropdown-divider"></div>
                <a class="dropdown-item" href="javascript: void(0);">Separated link</a>
              </div>
            </div><!-- /btn-group -->
            <div class="btn-group mr-1 mt-2" ngbDropdown>
              <button type="button" class="btn btn-info dropdown-toggle" ngbDropdownToggle>Info <i
                  class="mdi mdi-chevron-down"></i></button>
              <div class="dropdown-menu" ngbDropdownMenu>
                <a class="dropdown-item" href="javascript: void(0);">Action</a>
                <a class="dropdown-item" href="javascript: void(0);">Another action</a>
                <a class="dropdown-item" href="javascript: void(0);">Something else here</a>
                <div class="dropdown-divider"></div>
                <a class="dropdown-item" href="javascript: void(0);">Separated link</a>
              </div>
            </div><!-- /btn-group -->
            <div class="btn-group mr-1 mt-2" ngbDropdown>
              <button type="button" class="btn btn-warning dropdown-toggle" ngbDropdownToggle>Warning <i
                  class="mdi mdi-chevron-down"></i></button>
              <div class="dropdown-menu" ngbDropdownMenu>
                <a class="dropdown-item" href="javascript: void(0);">Action</a>
                <a class="dropdown-item" href="javascript: void(0);">Another action</a>
                <a class="dropdown-item" href="javascript: void(0);">Something else here</a>
                <div class="dropdown-divider"></div>
                <a class="dropdown-item" href="javascript: void(0);">Separated link</a>
              </div>
            </div><!-- /btn-group -->
            <div class="btn-group mr-1 mt-2" ngbDropdown>
              <button type="button" class="btn btn-danger dropdown-toggle" ngbDropdownToggle>Danger <i
                  class="mdi mdi-chevron-down"></i></button>
              <div class="dropdown-menu" ngbDropdownMenu>
                <a class="dropdown-item" href="javascript: void(0);">Action</a>
                <a class="dropdown-item" href="javascript: void(0);">Another action</a>
                <a class="dropdown-item" href="javascript: void(0);">Something else here</a>
                <div class="dropdown-divider"></div>
                <a class="dropdown-item" href="javascript: void(0);">Separated link</a>
              </div>
            </div><!-- /btn-group -->
          </div>

        </div>
      </div>

    </div> <!-- end col -->
  </div> <!-- end row -->


  <div class="row">
    <div class="col-lg-6">
      <div class="card">
        <div class="card-body">

          <h4 class="card-title">Split button dropdowns</h4>
          <p class="card-title-desc mb-3">The best part is you can do this with any button variant, too:</p>

          <div class="">
            <div class="btn-group mr-1 mt-2" ngbDropdown>
              <button type="button" class="btn btn-primary">Primary</button>
              <button type="button" class="btn btn-primary dropdown-toggle dropdown-toggle-split" ngbDropdownToggle>
                <i class="mdi mdi-chevron-down"></i>
              </button>
              <div class="dropdown-menu" ngbDropdownMenu>
                <a class="dropdown-item" href="javascript: void(0);">Action</a>
                <a class="dropdown-item" href="javascript: void(0);">Another action</a>
                <a class="dropdown-item" href="javascript: void(0);">Something else here</a>
                <div class="dropdown-divider"></div>
                <a class="dropdown-item" href="javascript: void(0);">Separated link</a>
              </div>
            </div><!-- /btn-group -->
            <div class="btn-group mr-1 mt-2" ngbDropdown>
              <button type="button" class="btn btn-light">Light</button>
              <button type="button" class="btn btn-light dropdown-toggle dropdown-toggle-split" ngbDropdownToggle>
                <i class="mdi mdi-chevron-down"></i>
              </button>
              <div class="dropdown-menu" ngbDropdownMenu>
                <a class="dropdown-item" href="javascript: void(0);">Action</a>
                <a class="dropdown-item" href="javascript: void(0);">Another action</a>
                <a class="dropdown-item" href="javascript: void(0);">Something else here</a>
                <div class="dropdown-divider"></div>
                <a class="dropdown-item" href="javascript: void(0);">Separated link</a>
              </div>
            </div><!-- /btn-group -->
            <div class="btn-group mr-1 mt-2" ngbDropdown>
              <button type="button" class="btn btn-success">Success</button>
              <button type="button" class="btn btn-success dropdown-toggle dropdown-toggle-split" ngbDropdownToggle>
                <i class="mdi mdi-chevron-down"></i>
              </button>
              <div class="dropdown-menu" ngbDropdownMenu>
                <a class="dropdown-item" href="javascript: void(0);">Action</a>
                <a class="dropdown-item" href="javascript: void(0);">Another action</a>
                <a class="dropdown-item" href="javascript: void(0);">Something else here</a>
                <div class="dropdown-divider"></div>
                <a class="dropdown-item" href="javascript: void(0);">Separated link</a>
              </div>
            </div><!-- /btn-group -->
            <div class="btn-group mr-1 mt-2" ngbDropdown>
              <button type="button" class="btn btn-info">Info</button>
              <button type="button" class="btn btn-info dropdown-toggle dropdown-toggle-split" ngbDropdownToggle>
                <i class="mdi mdi-chevron-down"></i>
              </button>
              <div class="dropdown-menu" ngbDropdownMenu>
                <a class="dropdown-item" href="javascript: void(0);">Action</a>
                <a class="dropdown-item" href="javascript: void(0);">Another action</a>
                <a class="dropdown-item" href="javascript: void(0);">Something else here</a>
                <div class="dropdown-divider"></div>
                <a class="dropdown-item" href="javascript: void(0);">Separated link</a>
              </div>
            </div><!-- /btn-group -->
            <div class="btn-group mr-1 mt-2" ngbDropdown>
              <button type="button" class="btn btn-warning">Warning</button>
              <button type="button" class="btn btn-warning dropdown-toggle dropdown-toggle-split" ngbDropdownToggle>
                <i class="mdi mdi-chevron-down"></i>
              </button>
              <div class="dropdown-menu" ngbDropdownMenu>
                <a class="dropdown-item" href="javascript: void(0);">Action</a>
                <a class="dropdown-item" href="javascript: void(0);">Another action</a>
                <a class="dropdown-item" href="javascript: void(0);">Something else here</a>
                <div class="dropdown-divider"></div>
                <a class="dropdown-item" href="javascript: void(0);">Separated link</a>
              </div>
            </div><!-- /btn-group -->
            <div class="btn-group mr-1 mt-2" ngbDropdown>
              <button type="button" class="btn btn-danger">Danger</button>
              <button type="button" class="btn btn-danger dropdown-toggle dropdown-toggle-split" ngbDropdownToggle>
                <i class="mdi mdi-chevron-down"></i>
              </button>
              <div class="dropdown-menu" ngbDropdownMenu>
                <a class="dropdown-item" href="javascript: void(0);">Action</a>
                <a class="dropdown-item" href="javascript: void(0);">Another action</a>
                <a class="dropdown-item" href="javascript: void(0);">Something else here</a>
                <div class="dropdown-divider"></div>
                <a class="dropdown-item" href="javascript: void(0);">Separated link</a>
              </div>
            </div><!-- /btn-group -->
          </div>

        </div>
      </div>
    </div>

    <div class="col-lg-6">
      <div class="card">
        <div class="card-body">

          <h4 class="card-title">Sizing</h4>
          <p class="card-title-desc mb-3">Button dropdowns work with buttons of
            all sizes, including default and split dropdown buttons.</p>

          <div class="">
            <!-- Large button groups (default and split) -->
            <div class="btn-group mr-1 mt-2" ngbDropdown>
              <button class="btn btn-primary btn-lg dropdown-toggle" ngbDropdownToggle>
                Large button <i class="mdi mdi-chevron-down"></i>
              </button>
              <div class="dropdown-menu" ngbDropdownMenu>
                <a class="dropdown-item" href="javascript: void(0);">Action</a>
                <a class="dropdown-item" href="javascript: void(0);">Another action</a>
                <a class="dropdown-item" href="javascript: void(0);">Something else here</a>
                <div class="dropdown-divider"></div>
                <a class="dropdown-item" href="javascript: void(0);">Separated link</a>
              </div>
            </div>
            <div class="btn-group mr-1 mt-2" ngbDropdown>
              <button class="btn btn-light btn-lg" type="button">
                Large button
              </button>
              <button type="button" class="btn btn-lg btn-light dropdown-toggle dropdown-toggle-split"
                ngbDropdownToggle>
                <i class="mdi mdi-chevron-down"></i>
              </button>
              <div class="dropdown-menu" ngbDropdownMenu>
                <a class="dropdown-item" href="javascript: void(0);">Action</a>
                <a class="dropdown-item" href="javascript: void(0);">Another action</a>
                <a class="dropdown-item" href="javascript: void(0);">Something else here</a>
                <div class="dropdown-divider"></div>
                <a class="dropdown-item" href="javascript: void(0);">Separated link</a>
              </div>
            </div>

            <!-- Small button groups (default and split) -->
            <div class="btn-group mr-1 mt-2" ngbDropdown>
              <button class="btn btn-primary btn-sm dropdown-toggle" type="button" ngbDropdownToggle>
                Small button <i class="mdi mdi-chevron-down"></i>
              </button>
              <div class="dropdown-menu" ngbDropdownMenu>
                <a class="dropdown-item" href="javascript: void(0);">Action</a>
                <a class="dropdown-item" href="javascript: void(0);">Another action</a>
                <a class="dropdown-item" href="javascript: void(0);">Something else here</a>
                <div class="dropdown-divider"></div>
                <a class="dropdown-item" href="javascript: void(0);">Separated link</a>
              </div>
            </div>
            <div class="btn-group mr-1 mt-2" ngbDropdown>
              <button class="btn btn-light btn-sm" type="button">
                Small button
              </button>
              <button type="button" class="btn btn-sm btn-light dropdown-toggle dropdown-toggle-split"
                ngbDropdownToggle>
                <i class="mdi mdi-chevron-down"></i>
              </button>
              <div class="dropdown-menu" ngbDropdownMenu>
                <a class="dropdown-item" href="javascript: void(0);">Action</a>
                <a class="dropdown-item" href="javascript: void(0);">Another action</a>
                <a class="dropdown-item" href="javascript: void(0);">Something else here</a>
                <div class="dropdown-divider"></div>
                <a class="dropdown-item" href="javascript: void(0);">Separated link</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> <!-- end col -->
  </div> <!-- end row -->


  <div class="row">
    <div class="col-lg-6">
      <div class="card">
        <div class="card-body">

          <h4 class="card-title">Dropup variation</h4>
          <p class="card-title-desc mb-3">Trigger dropdown menus above elements
            by adding <code class="highlighter-rouge">.dropup</code> to the parent
            element.</p>

          <div class="">
            <!-- Default dropup button -->
            <div class="btn-group dropup mt-2 mr-1" ngbDropdown placement='top'>
              <button type="button" class="btn btn-light dropdown-toggle" ngbDropdownToggle>Dropup <i
                  class="mdi mdi-chevron-up"></i></button>
              <div class="dropdown-menu" ngbDropdownMenu>
                <a class="dropdown-item" href="javascript: void(0);">Action</a>
                <a class="dropdown-item" href="javascript: void(0);">Another action</a>
                <a class="dropdown-item" href="javascript: void(0);">Something else here</a>
                <div class="dropdown-divider"></div>
                <a class="dropdown-item" href="javascript: void(0);">Separated link</a>
              </div>
            </div>

            <!-- Split dropup button -->
            <div class="btn-group dropup mt-2 mr-1" ngbDropdown placement='top'>
              <button type="button" class="btn btn-light">
                Split dropup
              </button>
              <button type="button" class="btn btn-light dropdown-toggle" ngbDropdownToggle>
                <i class="mdi mdi-chevron-up"></i>
              </button>
              <div class="dropdown-menu" ngbDropdownMenu>
                <a class="dropdown-item" href="javascript: void(0);">Action</a>
                <a class="dropdown-item" href="javascript: void(0);">Another action</a>
                <a class="dropdown-item" href="javascript: void(0);">Something else here</a>
                <div class="dropdown-divider"></div>
                <a class="dropdown-item" href="javascript: void(0);">Separated link</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-lg-6">
      <div class="card">
        <div class="card-body">
          <h4 class="card-title">Menu alignment</h4>
          <p class="card-title-desc">Add <code class="highlighter-rouge">.dropdown-menu-right</code>
            to a <code class="highlighter-rouge">.dropdown-menu</code> to right
            align the dropdown menu.</p>

          <div class="btn-group" ngbDropdown>
            <button type="button" class="btn btn-secondary dropdown-toggle" ngbDropdownToggle>
              Menu is right-aligned <i class="mdi mdi-chevron-down"></i>
            </button>
            <div class="dropdown-menu dropdown-menu-right" ngbDropdownMenu>
              <a class="dropdown-item" href="javascript: void(0);">Action</a>
              <a class="dropdown-item" href="javascript: void(0);">Another action</a>
              <a class="dropdown-item" href="javascript: void(0);">Something else here</a>
            </div>
          </div>

        </div>
      </div>
    </div> <!-- end col -->
  </div> <!-- end row -->


  <div class="row">
    <div class="col-lg-6">
      <div class="card">
        <div class="card-body">
          <h4 class="card-title">Dropright variation</h4>
          <p class="card-title-desc mb-3">Trigger dropdown menus at the right of the elements by adding
            <code>.dropright</code> to the parent element.
          </p>

          <div class="">
            <!-- Default dropright button -->
            <div class="btn-group mt-2 mr-1 dropright" ngbDropdown placement='right'>
              <button type="button" class="btn btn-light dropdown-toggle" ngbDropdownToggle>
                Dropright <i class="mdi mdi-chevron-right"></i>
              </button>
              <div class="dropdown-menu" ngbDropdownMenu>
                <a class="dropdown-item" href="javascript: void(0);">Action</a>
                <a class="dropdown-item" href="javascript: void(0);">Another action</a>
                <a class="dropdown-item" href="javascript: void(0);">Something else here</a>
              </div>
            </div>

            <!-- Split dropright button -->
            <div class="btn-group mt-2 mr-1 dropright" ngbDropdown placement='right'>
              <button type="button" class="btn btn-light">
                Split dropright
              </button>
              <button type="button" class="btn btn-light dropdown-toggle-split dropdown-toggle" ngbDropdownToggle>
                <i class="mdi mdi-chevron-right"></i>
              </button>
              <div class="dropdown-menu" ngbDropdownMenu>
                <a class="dropdown-item" href="javascript: void(0);">Action</a>
                <a class="dropdown-item" href="javascript: void(0);">Another action</a>
                <a class="dropdown-item" href="javascript: void(0);">Something else here</a>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>

    <div class="col-lg-6">
      <div class="card">
        <div class="card-body">

          <h4 class="card-title">Dropleft variation</h4>
          <p class="card-title-desc">Trigger dropdown menus at the right of the elements by adding
            <code>.dropleft</code> to the parent element.
          </p>

          <!-- Default dropright button -->
          <div class="btn-group mt-2 mr-1 dropleft" ngbDropdown placement='left'>
            <button type="button" class="btn btn-light dropdown-toggle" ngbDropdownToggle>
              <i class="mdi mdi-chevron-left"></i> Dropleft
            </button>
            <div class="dropdown-menu" ngbDropdownMenu>
              <a class="dropdown-item" href="javascript: void(0);">Action</a>
              <a class="dropdown-item" href="javascript: void(0);">Another action</a>
              <a class="dropdown-item" href="javascript: void(0);">Something else here</a>
            </div>
          </div>

          <!-- Split dropright button -->
          <div class="btn-group mt-2 mr-1">
            <div class="btn-group dropleft" ngbDropdown placement='left'>
              <button type="button" class="btn btn-light dropdown-toggle-split dropdown-toggle" ngbDropdownToggle>
                <i class="mdi mdi-chevron-left"></i>
              </button>
              <div class="dropdown-menu" ngbDropdownMenu>
                <a class="dropdown-item" href="javascript: void(0);">Action</a>
                <a class="dropdown-item" href="javascript: void(0);">Another action</a>
                <a class="dropdown-item" href="javascript: void(0);">Something else here</a>
              </div>
            </div>

            <button type="button" class="btn btn-light">
              Split Dropleft
            </button>
          </div>
        </div>
      </div>
    </div> <!-- end col -->
  </div> <!-- end row -->

</div> <!-- container-fluid -->
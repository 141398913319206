import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { UiModule } from '../../shared/ui/ui.module';
import { NgbPaginationModule, NgbTypeaheadModule, NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';

import { NgSelectModule } from '@ng-select/ng-select';

import { MasterRoutingModule } from './master-routing.module';
import { UserSortableDirective } from './usertable/user-sortable.directive';
import { RoleSortableDirective } from './roletable/role-sortable.directive';
import { AudittrailSortableDirective } from './audittrailtable/audittrail-sortable.directive';
import { UsertableComponent } from './usertable/usertable.component';
import { RoletableComponent } from './roletable/roletable.component';
import { AudittrailtableComponent } from './audittrailtable/audittrailtable.component';

@NgModule({
  declarations: [
    UsertableComponent, 
    RoletableComponent, 
    AudittrailtableComponent, 
    UserSortableDirective, 
    RoleSortableDirective,
    AudittrailSortableDirective
  ],
  imports: [
    CommonModule,
    MasterRoutingModule,
    UiModule,
    NgbDropdownModule,
    NgbPaginationModule,
    NgbTypeaheadModule,
    FormsModule,
    NgSelectModule
  ]
})
export class MasterModule { }

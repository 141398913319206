import { MenuItem } from './menu.model';

let menu1 = [
    {
        id: 2,
        label: 'MENUITEMS.DASHBOARDS.TEXT',
        icon: 'ri-dashboard-line',
        // badge: {
        //     variant: 'success',
        //     text: 'MENUITEMS.DASHBOARDS.BADGE',
        // },
        link: '/'
    },
    {
        id: 32,
        label: 'MENUITEMS.MONITORINGLOGS.TEXT',
        isTitle: true
    },
    {
        id: 58,
        label: 'MENUITEMS.MONITORINGLOGS.LIST.TRXLOG',
        icon: 'ri-dashboard-line',
        link: '/tables/trxlog'
    },
    {
        id: 59,
        label: 'MENUITEMS.MONITORINGLOGS.LIST.CREDITTRANSFERLOG',
        icon: 'ri-dashboard-line',
        link: '/tables/credittransferlog'
    },
    {
        id: 60,
        label: 'MENUITEMS.MONITORINGLOGS.LIST.REVERSECREDITTRANSFERLOG',
        icon: 'ri-dashboard-line',
        link: '/tables/reversecredittransferlog'
    },
    {
        id: 61,
        label: 'MENUITEMS.MONITORINGLOGS.LIST.NOTIFSETTLEMENTLOG',
        icon: 'ri-dashboard-line',
        link: '/tables/notifsettlementlog'
    },
    {
        id: 62,
        label: 'MENUITEMS.MONITORINGLOGS.LIST.FAILEDMGMTLOG',
        icon: 'ri-dashboard-line',
        link: '/tables/failedmgmtlog'
    },
    {
        id: 63,
        label: 'MENUITEMS.MONITORINGLOGS.LIST.FAILEDNOTIFSETTLEMENTLOG',
        icon: 'ri-dashboard-line',
        link: '/tables/failednotifsettlementlog'
    },
    {
        id: 64,
        label: 'MENUITEMS.MONITORINGLOGS.LIST.TRXSUSPECT',
        icon: 'ri-dashboard-line',
        link: '/tables/trxsuspect'
    },
    {
        id: 65,
        label: 'MENUITEMS.MASTER.TEXT',
        isTitle: true
    },
    {
        id: 66,
        label: 'MENUITEMS.MASTER.LIST.USER',
        icon: 'ri-dashboard-line',
        link: '/master/user'
    },
    {
        id: 67,
        label: 'MENUITEMS.MASTER.LIST.ROLE',
        icon: 'ri-dashboard-line',
        link: '/master/role'
    },
    {
        id: 68,
        label: 'MENUITEMS.MASTER.LIST.AUDITTRAIL',
        icon: 'ri-dashboard-line',
        link: '/master/audittrail'
    }
];

let menu2 = [
    {
        id: 32,
        label: 'MENUITEMS.MONITORINGLOGS.TEXT',
        isTitle: true
    },
    {
        id: 58,
        label: 'MENUITEMS.MONITORINGLOGS.LIST.TRXLOG',
        icon: 'ri-dashboard-line',
        link: '/tables/trxlog'
    },
    {
        id: 59,
        label: 'MENUITEMS.MONITORINGLOGS.LIST.CREDITTRANSFERLOG',
        icon: 'ri-dashboard-line',
        link: '/tables/credittransferlog'
    },
    {
        id: 60,
        label: 'MENUITEMS.MONITORINGLOGS.LIST.REVERSECREDITTRANSFERLOG',
        icon: 'ri-dashboard-line',
        link: '/tables/reversecredittransferlog'
    },
    {
        id: 61,
        label: 'MENUITEMS.MONITORINGLOGS.LIST.NOTIFSETTLEMENTLOG',
        icon: 'ri-dashboard-line',
        link: '/tables/notifsettlementlog'
    },
    {
        id: 62,
        label: 'MENUITEMS.MONITORINGLOGS.LIST.FAILEDMGMTLOG',
        icon: 'ri-dashboard-line',
        link: '/tables/failedmgmtlog'
    },
    {
        id: 63,
        label: 'MENUITEMS.MONITORINGLOGS.LIST.FAILEDNOTIFSETTLEMENTLOG',
        icon: 'ri-dashboard-line',
        link: '/tables/failednotifsettlementlog'
    },
    {
        id: 64,
        label: 'MENUITEMS.MONITORINGLOGS.LIST.TRXSUSPECT',
        icon: 'ri-dashboard-line',
        link: '/tables/trxsuspect'
    }
];

function getMenu() {
    if(localStorage.getItem('user') == 'admin@app.com') {
        return menu1;
    } else {
        return menu2;
    }
}

export const MENU: MenuItem[] = getMenu();

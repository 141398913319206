<div class="container-fluid">
  <app-pagetitle title="Dashboard" [breadcrumbItems]="breadCrumbItems"></app-pagetitle>

  <div class="row">
    <div class="col-xl-4">
      <div class="row">
        <div *ngFor="let stat of statData" class="col-xl-12">
          <app-stat [title]="stat.title" [value]="stat.value" [icon]="stat.icon"></app-stat>
        </div>
      </div>
      <!-- end row -->
    </div>

    <div class="col-xl-8">
      <div class="card">
        <div class="card-body">
          <!-- <div class="float-right d-none d-md-inline-block">
            <div class="btn-group mb-2">
              <button type="button" class="btn btn-sm btn-light">Today</button>
              <button type="button" class="btn btn-sm btn-light active">Weekly</button>
              <button type="button" class="btn btn-sm btn-light">Monthly</button>
            </div>
          </div> -->
          <!-- <div class="float-right">
            <select class="custom-select custom-select-sm">
              <option selected>Today</option>
              <option value="1">Nov</option>
              <option value="2">Oct</option>
              <option value="3">Sep</option>
              <option value="4">Aug</option>
            </select>
          </div> -->
          <h4 class="card-title mb-4">TRX Log</h4>
          <div>
            <apx-chart dir="ltr" class="apex-charts" [series]="revenueChart.series" [chart]="revenueChart.chart"
              [legend]="revenueChart.legend" [colors]="revenueChart.colors" [labels]="revenueChart.labels"
              [stroke]="revenueChart.stroke" [plotOptions]="revenueChart.plotOptions">
            </apx-chart>
          </div>
        </div>

        <div class="card-body border-top text-center">
          <div class="row">
            <div class="col-sm-4">
              <div class="d-inline-flex">
                <h5 class="mr-2">12,253</h5>
                <div class="text-success">
                  <i class="mdi mdi-menu-up font-size-14"> </i>2.2 %
                </div>
              </div>
              <p class="text-muted text-truncate mb-0">This month</p>
            </div>

            <div class="col-sm-4">
              <div class="mt-4 mt-sm-0">
                <p class="mb-2 text-muted text-truncate"><i class="mdi mdi-circle text-primary font-size-10 mr-1"></i>
                  This Year :</p>
                <div class="d-inline-flex">
                  <h5 class="mb-0 mr-2">34,254</h5>
                  <div class="text-success">
                    <i class="mdi mdi-menu-up font-size-14"> </i>2.1 %
                  </div>
                </div>
              </div>
            </div>
            <div class="col-sm-4">
              <div class="mt-4 mt-sm-0">
                <p class="mb-2 text-muted text-truncate"><i class="mdi mdi-circle text-success font-size-10 mr-1"></i>
                  Previous Year :</p>
                <div class="d-inline-flex">
                  <h5 class="mb-0">32,695</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-xl-4">
      <div class="card">
        <div class="card-body">
          <div class="float-right">
            <select class="custom-select custom-select-sm">
              <option selected>Today</option>
              <option value="1">Nov</option>
              <option value="2">Oct</option>
              <option value="3">Sep</option>
              <option value="4">Aug</option>
            </select>
          </div>

          <h4 class="card-title mb-3">TRX RC Log</h4>

          <apx-chart dir="ltr" class="apex-charts" [series]="salesAnalytics2.series" [chart]="salesAnalytics2.chart"
            [legend]="salesAnalytics2.legend" [colors]="salesAnalytics2.colors" [labels]="salesAnalytics2.labels"
            [dataLabels]="salesAnalytics2.dataLabels" [plotOptions]="salesAnalytics2.plotOptions">
          </apx-chart>

          <div>
            <!-- <div class="text-center">
              <p class="mb-2">Total logs</p>
              <h4>7652</h4>
              <div class="text-success">
                <i class="mdi mdi-menu-up font-size-14"> </i>2.2 %
              </div>
            </div> -->

            <div class="table-responsive mt-4">
              <table class="table table-hover mb-0 table-centered table-nowrap">
                <tbody>
                  <tr>
                    <td style="width: 60px;">
                      <div class="avatar-xs">
                        <div class="avatar-title rounded-circle bg-light">
                          <img src="assets/images/companies/img-1.png" alt="" height="20">
                        </div>
                      </div>
                    </td>

                    <td>
                      <h5 class="font-size-14 mb-0">#500</h5>
                    </td>
                    <td>
                      <div id="spak-chart1"></div>
                    </td>
                    <td>
                      <p class="text-muted mb-0">2478</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div class="avatar-xs">
                        <div class="avatar-title rounded-circle bg-light">
                          <img src="assets/images/companies/img-2.png" alt="" height="20">
                        </div>
                      </div>
                    </td>
                    <td>
                      <h5 class="font-size-14 mb-0">#409</h5>
                    </td>

                    <td>
                      <div id="spak-chart2"></div>
                    </td>
                    <td>
                      <p class="text-muted mb-0">2625</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div class="avatar-xs">
                        <div class="avatar-title rounded-circle bg-light">
                          <img src="assets/images/companies/img-3.png" alt="" height="20">
                        </div>
                      </div>
                    </td>
                    <td>
                      <h5 class="font-size-14 mb-0">#0</h5>
                    </td>
                    <td>
                      <div id="spak-chart3"></div>
                    </td>
                    <td>
                      <p class="text-muted mb-0">2856</p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div class="text-center mt-4">
              <a href="javascript: void(0);" class="btn btn-primary btn-sm">View more</a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-xl-4">
      <div class="card">
        <div class="card-body">
          <div class="float-right">
            <select class="custom-select custom-select-sm">
              <option selected>Today</option>
              <option value="1">Nov</option>
              <option value="2">Oct</option>
              <option value="3">Sep</option>
              <option value="4">Aug</option>
            </select>
          </div>
          <h4 class="card-title mb-4">TRX Suspect</h4>

          <apx-chart dir="ltr" class="apex-charts" [series]="salesAnalytics.series" [chart]="salesAnalytics.chart"
            [legend]="salesAnalytics.legend" [colors]="salesAnalytics.colors" [labels]="salesAnalytics.labels"
            [dataLabels]="salesAnalytics.dataLabels" [plotOptions]="salesAnalytics.plotOptions">
          </apx-chart>
          <div>
            <!-- <div class="text-center">
              <p class="mb-2">Total logs</p>
              <h4>7652</h4>
              <div class="text-success">
                <i class="mdi mdi-menu-up font-size-14"> </i>2.2 %
              </div>
            </div> -->

            <div class="table-responsive mt-4">
              <table class="table table-hover mb-0 table-centered table-nowrap">
                <tbody>
                  <tr>
                    <td style="width: 60px;">
                      <div class="avatar-xs">
                        <div class="avatar-title rounded-circle bg-light">
                          <img src="assets/images/companies/img-1.png" alt="" height="20">
                        </div>
                      </div>
                    </td>

                    <td>
                      <h5 class="font-size-14 mb-0">#500</h5>
                    </td>
                    <td>
                      <div id="spak-chart1"></div>
                    </td>
                    <td>
                      <p class="text-muted mb-0">2478</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div class="avatar-xs">
                        <div class="avatar-title rounded-circle bg-light">
                          <img src="assets/images/companies/img-2.png" alt="" height="20">
                        </div>
                      </div>
                    </td>
                    <td>
                      <h5 class="font-size-14 mb-0">#409</h5>
                    </td>

                    <td>
                      <div id="spak-chart2"></div>
                    </td>
                    <td>
                      <p class="text-muted mb-0">2625</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div class="avatar-xs">
                        <div class="avatar-title rounded-circle bg-light">
                          <img src="assets/images/companies/img-3.png" alt="" height="20">
                        </div>
                      </div>
                    </td>
                    <td>
                      <h5 class="font-size-14 mb-0">#0</h5>
                    </td>
                    <td>
                      <div id="spak-chart3"></div>
                    </td>
                    <td>
                      <p class="text-muted mb-0">2856</p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div class="text-center mt-4">
              <a href="javascript: void(0);" class="btn btn-primary btn-sm">View more</a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-xl-4">
      <div class="card">
        <div class="card-body">
          <div class="float-right">
            <select class="custom-select custom-select-sm">
              <option selected>Today</option>
              <option value="1">Nov</option>
              <option value="2">Oct</option>
              <option value="3">Sep</option>
              <option value="4">Aug</option>
            </select>
          </div>
          <h4 class="card-title mb-4">Failed MGMT Log</h4>
          <apx-chart dir="ltr" class="apex-charts" [series]="salesAnalytics3.series" [chart]="salesAnalytics3.chart"
            [legend]="salesAnalytics3.legend" [colors]="salesAnalytics3.colors" [labels]="salesAnalytics3.labels"
            [dataLabels]="salesAnalytics3.dataLabels" [plotOptions]="salesAnalytics3.plotOptions">
          </apx-chart>
          <div>
            <!-- <div class="text-center">
              <p class="mb-2">Total logs</p>
              <h4>7652</h4>
              <div class="text-success">
                <i class="mdi mdi-menu-up font-size-14"> </i>2.2 %
              </div>
            </div> -->

            <div class="table-responsive mt-4">
              <table class="table table-hover mb-0 table-centered table-nowrap">
                <tbody>
                  <tr>
                    <td style="width: 60px;">
                      <div class="avatar-xs">
                        <div class="avatar-title rounded-circle bg-light">
                          <img src="assets/images/companies/img-1.png" alt="" height="20">
                        </div>
                      </div>
                    </td>

                    <td>
                      <h5 class="font-size-14 mb-0">#500</h5>
                    </td>
                    <td>
                      <div id="spak-chart1"></div>
                    </td>
                    <td>
                      <p class="text-muted mb-0">2478</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div class="avatar-xs">
                        <div class="avatar-title rounded-circle bg-light">
                          <img src="assets/images/companies/img-2.png" alt="" height="20">
                        </div>
                      </div>
                    </td>
                    <td>
                      <h5 class="font-size-14 mb-0">#409</h5>
                    </td>

                    <td>
                      <div id="spak-chart2"></div>
                    </td>
                    <td>
                      <p class="text-muted mb-0">2625</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div class="avatar-xs">
                        <div class="avatar-title rounded-circle bg-light">
                          <img src="assets/images/companies/img-3.png" alt="" height="20">
                        </div>
                      </div>
                    </td>
                    <td>
                      <h5 class="font-size-14 mb-0">#0</h5>
                    </td>
                    <td>
                      <div id="spak-chart3"></div>
                    </td>
                    <td>
                      <p class="text-muted mb-0">2856</p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div class="text-center mt-4">
              <a href="javascript: void(0);" class="btn btn-primary btn-sm">View more</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- end row -->
</div>
